<template>
  <metabase :code="894" />
</template>

<script>
import Metabase from '../components/Metabase.vue';

export default {
    components: {
        Metabase,
    },
};
</script>
